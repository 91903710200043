.contact-header-fon {
    background: rgba(0, 0, 0, 0.4);

    @include media-breakpoint-up(xs) {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    @include media-breakpoint-up(sm) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    @include media-breakpoint-up(md) {
        padding-top: 45px;
        padding-bottom: 20px;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 24px;
        padding-bottom: 19px;
    }
}
