// Alerts
// Error
$alert-danger-bg: 			#f8d7da;
$alert-danger-border-color: #f5c6cb;
$alert-danger-color: 		#721c24;
// // Success
$alert-success-bg: 			#d4edda;
$alert-success-border-color:#c3e6cb;
$alert-success-color: 		#155724;
// // info
$alert-info-bg: 			rgba(60, 118, 61, 0.2);
$alert-info-border-color: 	rgb(60, 118, 61);
$alert-info-color: 			#ffffff;

.alert {
	padding: 15px 25px;
	font-size: 1em;
	line-height: 1.2;
	font-weight: normal;
	width: 100%;
	margin: 0 0 25px 0;

	// @include susy-breakpoint(600px){
	// 	padding: 10px 17px;
	// }

	span {
		display: block;
	}

	&--error {
		background-color: $alert-danger-bg;
		color: $alert-danger-color;
	}

	&--success {
		background-color: $alert-success-bg;
		color: $alert-success-color;
	}
}