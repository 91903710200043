.menu-fon {
    background: $background-menu;
    position: relative;
    z-index: 10;

    @include media-breakpoint-up(lg) {
        box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.7);
    }
}

.menu-mobile, .menu-mobile-contacts {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 63px;
    order: 4;
    z-index: 0;

    @include media-breakpoint-up(xs) {
        box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.7);
        padding-top: 19px;
        padding-bottom: 32px;
        background: $background-menu-hover;
    }
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.menu {
    align-items: center;
    margin: 0;
    justify-content: space-between;
    list-style: none;
    font-family: $font-family-menu;
    font-weight: normal;

    @include media-breakpoint-up(xs) {
        display: none;
        flex-direction: column;
        font-size: 0.833em;
        padding-left: 0;
        padding-right: 0;
        background: $background-menu-hover;
    }
    @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: row;
        font-size: 0.836em;
        padding-left: 15px;
        padding-right: 15px;
        background: none;
    }
    @include media-breakpoint-up(xl) {
        font-size: 0.981em;
    }

    &__item {
        position: relative;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xs) {
            width: 100%;
        }
        @include media-breakpoint-up(lg) {
            width: auto;
            text-align: center;
            flex-direction: row;
        }
    }
    
    &__link {
        text-decoration: none;
        color: #fff;

        @include media-breakpoint-up(xs) {
            padding-top: 10px;
            padding-bottom: 7px;
            padding-left: 28px;
            padding-right: 28px;
            line-height: 1.47;
            width: 100%;
        }
        @include media-breakpoint-up(lg) {
            padding-top: 28px;
            padding-bottom: 32px;
            padding-left: 15px;
            padding-right: 15px;
            line-height: 1.14;
        }

        .menu__item:hover & {
            background: $background-menu-hover;
        }
    }
}

.aling-right {
    .menu__item {
        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
        }
    }
}

.open {
    display: flex;
}


.menu__submenu {
    
    top: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
	flex: 1 1 auto;

    align-items: stretch;
    margin: 0;
    justify-content: space-between;
    list-style: none;
    font-family: $font-family-menu;
    font-weight: normal;
    background-color: $background-menu-hover;

    .menu__item:hover & {
        display: flex;
    }

    @include media-breakpoint-up(xs) {
		font-size: 0.833rem;
    }
    @include media-breakpoint-up(lg) {
        position: absolute;
        font-size: 0.836rem;
        display: none;
		padding: 20px 0 20px;
		min-width: 260%;
    }
    @include media-breakpoint-up(xl) {
        font-size: 0.981rem;
    }
}

.menu__submenu-item {
    display: flex;
    flex: 1 1 auto;
}

.menu__submenu-link {
    width: 100%;
    text-decoration: none;
    color: #fff;
    text-align: left;

    @include media-breakpoint-up(xs) {
        padding-top: 10px;
        padding-bottom: 7px;
        padding-left: 38px;
        padding-right: 28px;
        line-height: 1.47;
        width: 100%;

        &:before {
            content: "-"
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 8px;
        padding-bottom: 10px;
        padding-left: 35px;
        padding-right: 35px;
		line-height: 1.14;

		&:before {
            content: none;
        }
		
		.menu__submenu-item:hover & {
			background-color: $background-menu;
		}
    }
}

.menu__submenu-link--level3 {
	
	&:before {
		content: "--"
	}

	@include media-breakpoint-up(lg) {
		padding-left: 55px;
		position: relative;

		&:before {
			content: "-";
			position: absolute;
			left: 35px;
		}
	}
}