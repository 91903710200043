.news {
	margin-bottom: 30px;
	display: block;
	text-decoration: none;

	&__img {
		width: 100%;
		margin-bottom: 20px;

		@include media-breakpoint-up(xs) {
			&--col {
				flex: 0 0 100%;
				max-width: 100%;
			}

			&--height-100 {
				height: 100%;
			}
		}

		@include media-breakpoint-up(sm) {
			&--col {
                margin-bottom: 0;
				flex: 0 0 50%;
				max-width: 50%;
			}
		}

		@include media-breakpoint-up(md) {
			&--col {
				flex: 0 0 ((255/855) * 100%);
				max-width: ((255/855) * 100%);
			}
		}
	}

	&__heading {
		@extend .headings-h3;
		font-family: $font-family-regular;
		text-transform: uppercase;
		margin-bottom: 13px;
	}

	&:hover {
		.news__heading {
			text-decoration: underline;
		}
	}

	&__preview {
		color: #4d4d4d;
		font-size: 0.858em;
		line-height: 1.286;
		margin-bottom: 7px;
	}

	&__date {
		font-size: 0.735em;
		color: $color-heading;

		span {
			font-family: $font-family-bold;
		}
	}

	&--border {
		padding-bottom: 30px;
		border-bottom: 1px solid #ededed;

		&:last-child {
			padding-bottom: 0;
			border: none;
		}
	}
}
