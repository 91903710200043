.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 20;

    a {
        cursor: pointer;
    }

    img {
        background: $background-menu;
        border-radius: 100%;
        position: absolute;
        box-shadow: 0 18px 27px -11px rgba(0, 0, 0, 0.7);
        top: 44.9%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-up(xs) {
            padding: 13px;
            min-width: 115px;
        }
        @include media-breakpoint-up(sm) {
            padding: 11.5%;
            min-width: 140px;
        }
        @include media-breakpoint-up(md) {
            min-width: 180px;
        }
    }
}
