.languages-header {
	display: flex;
	color: #fff;

	@include media-breakpoint-up(xs) {
		font-size: 0.644em;
	}

	@include media-breakpoint-up(sm) {
		font-size: 0.75em;
	}

	@include media-breakpoint-up(lg) {
        font-size: 0.907em;
		padding-left: 5.8%;
	}
	@include media-breakpoint-up(xl) {
		padding-left: 45px;
    }
    
	&__link {
		margin-bottom: -4px;

		@include media-breakpoint-up(xs) {
			padding-left: 10px;
		}
		@include media-breakpoint-up(lg) {
			padding-left: 10px;
		}
		@include media-breakpoint-up(xl) {
			padding-left: 17px;
		}

		&:first-child {
			padding-left: 0;
		}

		a {
			color: #ffffff;
			text-decoration: none;
			white-space: nowrap;

			&:hover {
				text-decoration: underline;
				//font-weight: bold;
			}
		}

		span {
			color: #ffffff;
			white-space: nowrap;
			font-weight: bold;
		}

		&--font {
			font-family: $font-family-lang;
		}
	}
}
