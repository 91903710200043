.ware {

    &__description {
        padding: 0 8.4%;
        background: $color-fon-block;
    }

    &__partition {
        line-height: 1.24;
        font-size: 0.859em;
        font-family: $font-family-regular;

        padding: 12px 0;
        border-bottom: 1px solid #ededed;

        &:last-child {
            border: none;
        }
    }

    &__list {
        list-style: none;
        margin: 0;
        line-height: 1.24;
        font-size: 14.603px;
        font-family: $font-family-copy;
    }

	&__images {
		margin-bottom: 30px;
	}
	
	&__images-slider {
		grid-column-start: 1;
		grid-column-end: 5;
		// grid-row-start: 1;
		// grid-row-end: 4;
	}
	
	&__images-slider-inner {
		border: 1px solid #ddd;
		aspect-ratio: 3/2;

		.swiper-slide {
			display: flex;
			justify-content: center;
		}

		.swiper-pagination-bullet {
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #9d1807;
			margin: 0 4px;
			opacity: 0.5;

			&.swiper-pagination-bullet-active {
				opacity: 1;
			}
		}
	}

	&__images-slider-controls {
		display: flex;
		// grid-template-columns: repeat(4, 1fr);
		gap: 15px;
		margin-top: 15px;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__images-slider-control {
		border: 1px solid #ddd;
		width: calc(25% - (45px / 4));
		aspect-ratio: 3/2;
		display: flex;
		justify-content: center;

		&--active {
			border-color: #9d1807;
		}
	}
}
