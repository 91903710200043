.stock {
    &--billboard {
        width: 100%;
    }

    img {
        width: 100%;
    }

    &__big {
        @include media-breakpoint-up(xs) {
            display: none;
        }
        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &__small {
        @include media-breakpoint-up(xs) {
            display: block;
        }
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}
