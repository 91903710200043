/* &__hover {
        opacity: 0;
        transition: opacity 0.5s;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        color: #fff;
        line-height: 1;

        .button--custom {
            line-height: 1;

            @include media-breakpoint-up(xs) {
                padding: 2vw 3vw;
                font-size: 4vw;
            }

            @include media-breakpoint-up(sm) {
                padding: 15px 40px;
                font-size: 18px;
            }

            @include media-breakpoint-up(md) {
                padding: 1vw 2vw;
                font-size: 2vw;
            }

            @include media-breakpoint-up(lg) {
                padding: 15px 40px;
                font-size: 18px;
            }
        }
    }
    &__heading {
        font-family: $font-family-bold;
        text-transform: uppercase;

        @include media-breakpoint-up(xs) {
            font-size: 7vw;
        }

        @include media-breakpoint-up(sm) {
            font-size: 40px;
        }

        @include media-breakpoint-up(md) {
            font-size: 4vw;
        }

        @include media-breakpoint-up(lg) {
            font-size: 40px;
        }
    }

    &__details {
        font-family: $font-family-note;
        text-transform: uppercase;

        @include media-breakpoint-up(xs) {
            font-size: 5vw;
            margin-bottom: 2vw;
        }

        @include media-breakpoint-up(sm) {
            font-size: 24px;
            font-family: $font-family-note;
            margin-bottom: 55px;
        }

        @include media-breakpoint-up(md) {
            font-size: 3vw;
            margin-bottom: 2vw;
        }

        @include media-breakpoint-up(lg) {
            font-size: 24px;
            font-family: $font-family-note;
            margin-bottom: 55px;
        }
    } */

.gallery {
    padding: 0 1px 2px 1px;
    position: relative;
    overflow: hidden;
    display: grid;

    @include media-breakpoint-up(xs) {
        padding-left: 0;
        padding-right: 0;
    }

    @include media-breakpoint-up(sm) {
        padding: 0 1px 2px 1px;

        &:first-child,
        &:nth-child(odd) {
            padding-left: 0;
        }

        &:last-child,
        &:nth-child(even) {
            padding-right: 0;
        }
    }

    @include media-breakpoint-up(md) {
        &:nth-child(odd) {
            padding-left: 1px;
        }
        &:first-child {
            padding-left: 0;
        }
        &:nth-child(even) {
            padding-right: 1px;
        }
    }

    &__link {
        position: relative;
        //display: block;
        overflow: hidden;
        margin: 0 !important;

        &::after {
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.6);
            transition: opacity 1.5s cubic-bezier(0.19, 1, 0.22, 1);
            display: block;
            height: 100%;
            z-index: 2;
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            padding: 0 3px 6px 3px;
        }

        img {
            transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
            width: 100%;
            transform: scale(1.02);
        }

        &:hover {
            &::before {
                left: 50%;
                position: absolute;
                top: 50%;
                background: url(../img/gallery/magnifier.png) no-repeat;
                width: 35px;
                height: 35px;
                margin: -18px 0 0 -18px;
                content: "";
                display: block;
                z-index: 20;
            }

            img {
                transform: scale(1.2);
            }

            &::after,
            .gallery__hover {
                opacity: 1;
            }
        }
    }
}

.gallery--col-md-5 {
	@include media-breakpoint-up(md) {
		flex: 0 0 (100% / 5);
        max-width: (100% / 5);

		&:nth-child(6n + 0) {
			padding-left: 0;
		}
		&:nth-child(5n + 0) {
			padding-right: 0;
		}
	}
}
