/* ==========================================================================
   #SECTION
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

.section {
    @include clearfix; // padding: 0 10px;
    font-family: $font-family-copy;
    width: 100%;
    margin: 0 auto;
}

.section--header {
    background-image: url(../img/header/billboard.jpg);
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(xs) {
        background-position: 85% 0;
        background-size: cover;
    }
    @include media-breakpoint-up(sm) {
        background-position: center;
    }
    @include media-breakpoint-up(lg) {
        background-size: auto;
        background-position: center -60px;
    }
}

.section--header-border {
    border-bottom: 2px solid #ededed;
}

.section--stock {
    background: $color-fon-block;
    @include media-breakpoint-up(xs) {
        padding: 3.93% 0;
    }
    @include media-breakpoint-up(md) {
        padding: 3.93% 0;
    }
}

.section--content {
}

.section--galley {
}

.section--slider {
    padding-bottom: 50px;
    background: #fff;
    z-index: 2;
}

.section--news {
    background: $color-fon-block;
    padding-top: 53px;
    padding-bottom: 37px;
    box-shadow: 0px 4px 19px -8px #00000085;
    position: relative;
    z-index: 1;
}

.section--map {
    width: 100%;
    min-height: 400px;
    max-height: 800px;
}

.section--footer {
    padding: 35px 0;
    background: $color-fon-dark;
    color: #fff;
    font-size: 0.853em;
    border-top: 6px solid #b32317;
}

.section--contacts {
    box-shadow: 0px 4px 19px -8px #00000085;
    position: relative;
    z-index: 1;
}

.section--filter {
    box-shadow: 0px 4px 19px -8px #00000085;
    position: relative;
    z-index: 1;
}

.section--breadcrumbs {
    background: #fff;
    z-index: 2;
}

.section--heading {
    background: #fff;
    z-index: 2;
}