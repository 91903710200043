.assortment {
	
	height: 100%;

	&__link {
		height: 100%;
		display: block;
		border: 1px solid #e8e4e2;
		text-decoration: none;
		color: $color-secondary;


		&:hover {
			text-decoration: underline;
		}
	}

	&__img {
		// height: 180px;
		display: flex;
		align-items: center;
		margin-bottom: 17px;

		img {
			// height: 100%;
			// width: 100%;
			object-fit: contain;
		}
	}

	&__title {
		font-family: $font-family-regular;
		font-size: 1.042em;
		line-height: 1.175;
		text-align: center;
		text-transform: uppercase;
		padding: 0 10px 20px;
	}

	&__swiper {
		aspect-ratio: 3/2;
		min-width: 100%;
	}

	&__swiper-slide {
		display: flex;
		justify-content: center;
	}

	&__swiper .swiper-pagination {

		@include media-breakpoint-down(md) {
			display: flex;
			justify-content: center;
		}

		@include media-breakpoint-up(lg) {
			top: 0;
			bottom: 0;
			display: flex;
			align-items: stretch;
		}
	}

	&__swiper-pagination-bullet {
		
		@include media-breakpoint-down(md) {
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #9d1807;
			margin: 0 4px;
			opacity: 0.5;
		}

		@include media-breakpoint-up(lg) {
			width: auto;
			flex-grow: 1;
			display: flex;
			justify-content: center;
			height: 100%;
		}
	}

	&__swiper-pagination-bullet.swiper-pagination-bullet-active {

		@include media-breakpoint-down(md) {
			opacity: 1;
		}
		
		@include media-breakpoint-up(lg) {
			background: none;
		}
	}

	&__swiper-pagination-bullet:after {
		
		@include media-breakpoint-up(lg) {
			content: ' ';
			align-self: end;
			height: 2px;
			background-color: #e8e4e2;
			margin-bottom: 2px;
			width: 80%;
		}
	}

	.swiper-pagination-bullet-active.assortment__swiper-pagination-bullet:after {

		@include media-breakpoint-up(lg) {
			background-color: #9d1807;
		}
	}
}
