///* ========================================================================
//   #COLORS
//   ======================================================================== */

$color-primary: #333333;
$color-secondary: #333333;

$hover-text: #b877d6;

$color-fon-block: #f8f8f8;
$color-fon-dark: #631e0e;

$color-text: $color-secondary;
$color-heading: #8c2212;
$color-accent: #1a8fd2;
$color-note: #4d4d4d;

$background-menu: #b32317;
$background-menu-hover: #9d1907;


$button: #9d1907;
$border-color-gray: #ededed;
$border-color: #dddddd;
$button-color: #fff;
$button-hover: darken($button, 7%);
