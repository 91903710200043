$input-mb: 28px;
$input-danger-color: #721c24;

.input {

	&--text {
		display: inline-block;
		width: 100%;
		max-width: 100%;
		padding: 10px;
		margin-bottom: $input-mb;
		border: 1px solid #ededed;
		letter-spacing: -0.04em;

		@include media-breakpoint-up(md) {
			padding: 17px 20px;
		}
	}
	
	&--error {
		color: $input-danger-color;
		border-color: $input-danger-color;
	}

	&.input--error::-webkit-input-placeholder, {
		color: $input-danger-color;
	}

	&.input--error:-moz-placeholder {
		color: $input-danger-color;
	}

	&.input--error::-moz-placeholder {
		color: $input-danger-color;
	}

	&.input--error:-ms-input-placeholder {
		color: $input-danger-color;
	}


	&--full-height {
		height: calc(100% - #{$input-mb});
	}

	&--no-resize {
		resize: none;
	}
}