.pagination {
    display: flex;
    list-style: none;   
    justify-content: flex-end;
    align-items: center;

    &__item {
        padding-left: 10px;

        &--current {
            span {
                @extend .pagination__link:hover;
                font-family: $font-family-bold;
            }
        }
    }

    &__link {
        color: $color-primary;
        text-decoration: none;

        &:hover {
            color: $color-heading;
            text-decoration: underline;
        }
    }

    &__prev {
        transform: rotate(180deg);
    }

    &__arrow {
        filter: grayscale(1) brightness(2.3);
        width: 17px;
    }
}