.map {
    width: 100%;

    @include media-breakpoint-up(xs) {
        height: 500px;
    }
}

.map-menu {
    padding-top: 17px;

    &--container {
        @extend .products--container;
    }

    &--row {
        justify-content: space-between;
        align-items: center;
    }

    &__item {
        display: flex;
        justify-content: center;
        border-right: 1px solid #cccccc;
        margin-bottom: 17px;

        &:first-child,
        &:nth-child(4n + 0) {
            border-left: 1px solid #cccccc;
        }

        &--active a {
            font-family: $font-family-bold;
            color: #9d1907;
            text-decoration: none;
        }
    }

    &__link {
        color: #333333;
        font-size: 0.981em;
        line-height: 1.175;
        padding: 5px 0;

        &:hover {
            text-decoration: none;
        }
    }
}

.circle-marker {
    display: flex;
    width: 40px;
    height: 37px;
    justify-content: center;
    align-items: center;

    &__point {
        color: #ffffff;
        font-weight: bold;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        border-radius: 100%;
        border: 2px solid #fff;
        background: #b32317;
        box-shadow: 0 5px 10px 0 #00000030;
    }

    &__center {
        background: #fff;
        width: 40%;
        height: 40%;
        border-radius: 100%;
    }

    &__content {
        white-space: nowrap;
        display: flex;
        height: 32px;
        align-items: center;
        background: #ffffff9c;
        padding: 0 20px 0 34px;
        position: absolute;
        top: 3px;
        left: 10px;
        z-index: 0;
        border-radius: 20px;
        width: 100px;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.ymap {
    height: 100%;
}
