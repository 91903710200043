.gallery-product {
    position: relative;
    overflow: hidden;
    padding: 0 2px 4px 2px;

    @include media-breakpoint-up(xs) {
        padding-left: 0;
        padding-right: 0;
    }

    @include media-breakpoint-up(sm) {
        padding: 0 2px 4px 2px;

        &:first-child,
        &:nth-child(odd) {
            padding-left: 0;
        }

        &:last-child,
        &:nth-child(even) {
            padding-right: 0;
        }
    }

    @include media-breakpoint-up(md) {
        &:nth-child(odd) {
            padding-left: 2px;
        }
        &:first-child {
            padding-left: 0;
        }
        &:nth-child(even) {
            padding-right: 2px;
        }
        &:nth-child(3n + 1) {
            padding-left: 0;
            padding-right: 2px;
        }
        &:nth-child(4n + 0) {
            padding-left: 0;
            padding-right: 2px;
        }
        &:nth-child(3n + 0) {
            padding-right: 0;
        }
    }

    &__link {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0 !important;

        @include media-breakpoint-up(xs) {
            height: 68vw;
        }

        @include media-breakpoint-up(sm) {
            height: 36vw;
        }

        @include media-breakpoint-up(md) {
            height: 24vw;
        }

        @include media-breakpoint-up(lg) {
            height: 18vw;
        }

        @include media-breakpoint-up(xl) {
            height: 212px;
        }

        .gallery-product__img {
            transition: transform 1.5s cubic-bezier(0.19, 1, 0.22, 1);
            max-height: 100%;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1.02);
        }

        &:hover {
            .gallery-product__title{
                text-decoration: underline;
            }
        }
    }

    &__content {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.55);
        padding: 3.7% 5.5%;
        min-height: 67px;
        width: 100%;
        height: 21.7%;
    }

    &__icon {
        flex: 0 0 26.5%;
        max-width: 26.5%;
        height: 100%;
        margin-right: 4.1%;

        img {
            height: 100%;
        }
    }

    &__title {
        font-family: $font-family-regular;
        color: #ffffff;
        font-size: 0.909em;
        line-height: 1.228;
    }
}
