.foundation-date {
	color: $color-heading;
	display: flex;

    -ms-flex-pack: end;
    justify-content: flex-start;
    padding-top: 5px;
    font-family: 'OpenSans-Semibold';


	@include media-breakpoint-up(xs) {
		justify-content: center;
		padding-top: 30px;
	}

	@include media-breakpoint-up(sm) {
		padding-top: 45px;
	}

	@include media-breakpoint-up(md) {
		padding-top: 65px;
	}	

	@include media-breakpoint-up(xl) {
		padding-right: 240px;
		justify-content: flex-end;
		padding-top: 5px;
	}

	&--homepage {
		color: #fff;
	}	
}

