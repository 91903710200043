.form {
	background-color: #f8f8f8;

	&__submit {
		// @include fontsize(25px, 1.5, 1.2, 1);
		padding: 15px;
		margin-bottom: 28px;

		@include media-breakpoint-up(md) {
			padding: 20px 15px;
		}
	}

	&__link {
		color: inherit;

		&:hover {
			text-decoration: none;
		}
	}

	&__alert {
		display: none;
	}

	.field-desc,
	.form__link {
		// @include fontsize(21px, 1.4, 1.2, 1);
		letter-spacing: -0.029em;
		line-height: 1.2;
	}
}