///* ========================================================================
//   #FONTS
//   ======================================================================== */

// Переменные, содержашие font-family по назначению
$font-family-copy:		    'OpenSans-Light', sans-serif;
$font-family-heading: 	    'OpenSans-Light', sans-serif;
$font-family-subheading: 	'OpenSans-Light', sans-serif;
$font-family-bold: 	        'OpenSans-Semibold', sans-serif;
$font-family-menu: 	        'OpenSans', sans-serif;
$font-family-regular: 	    'OpenSans', sans-serif;
$font-family-accent: 	    'OpenSans-Semibold', sans-serif;
$font-family-note: 	        'OpenSans-Light', sans-serif;
$font-family-lang: 	        'AdobeFangsongStd-Regular-Alphabetic', sans-serif;

$font-size-default: $default-size;

