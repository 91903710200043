.download {
	background-color: #f8f8f8;
	display: flex;
	align-items: center;
	padding: 15px;
	justify-content: space-between;

	& ~ & {
		margin-top: -25px;
	}

	&__icon {
		flex: 0 0 25px;
	}

	&__btn {
		display: flex;
		align-items: center;
	
		img {
			flex: 0 0 25px;
			max-width: 25px;
		}
	}

	&__materials {
		display: flex;
		align-items: center;

		img {
			max-height: 30px;
		}
	}

	&__filename {
		text-decoration: none;
		font-family: $font-family-bold;
		color: $color-primary;

		&:hover {
			text-decoration: underline;
		}
	}
}
