.list-category {
	background-color: #f8f8f8;
	list-style: none;
	margin: 0;
	font-family: $font-family-regular;
	font-size: 0.978em;
	line-height: 1.124;

	&__item {
		display: flex;
		align-items: center;
		padding: 0 20px;

		&:hover {
			background: #9d1807;

			.list-category {
				&__link {
					color: #ffffff;
					border-bottom: 1px solid transparent;
				}
			}
		}

		&:last-child,
		&:hover:last-child {
			.list-category {
				&__link {
					border-bottom: none;
				}
			}
		}
	}

	&__link {
		display: flex;
		align-items: center;
		min-height: 65px;
		width: 100%;
		border-bottom: 1px solid #ededed;
		color: $color-primary;
		text-decoration: none;
		padding: 15px 0;
	}

	&__active {
		@extend .list-category__item:hover;
		color: #ffffff;
	}
}
