.user-content {

	// font-size: 12.5px;
	
	h1 {
		@extend .headings-h1;
	}

	h2 {
        @extend .headings-h2;
	}
	
    h3 {
        @extend .headings-h3;
    }
	
	p,
	ul, {
		margin-bottom: 1.412em;
		// font-size: 12.5px;

		@include media-breakpoint-up(sm) {
			font-size: $default-size;
		}
	}

	p,
    ul {
        &:last-child {
            margin-bottom: 0;
        }
	}
	
    p + ul {
        margin-top: -12px;
	}
	
	img{
		max-width: 100%;
	}

    strong {
        font-family: $font-family-bold;
	}
	
	table {
		overflow-x: auto;
		white-space: nowrap;
		display: block;
		
		@include media-breakpoint-up(md) {
			overflow-x: initial;
			white-space: initial;
			display: table;
		}
	}
	
	td,
	th {
		border-top: 1px solid #dddddd;
		padding: 10px 10px;
		
		&:first-child {
			// @include col-custom-width-precent(475, 825);
			padding-left: 0;
		}
		
		&:last-child {
			// @include col-custom-width-precent(350, 825);
			padding-right: 0;
		}
	}

	tr:last-child td,
	tr:last-child th {
		border-bottom: 1px solid #dddddd;
	}

	.align_left {
		float: left;
		margin-right: 20px;
	}

	.align_right {
		float: right;
		margin-left: 20px;
	}

	.align_center {
		text-align: center;
	}
}
