$button-width: 30px;
$button-height: 23px;
$bar-thickness: 5px;
$button-pad: ($button-width / 2) * ($button-width / 120px);
$button-trans-delay: 0.3s;

.toggle-humburger {
  display: block;
  position: relative;
  overflow: hidden;
  padding: 0;
  width: $button-width;
  height: $button-height;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background $button-trans-delay;
  z-index: 2;
  
}

.toggle-humburger:focus {
  outline: none;
}

.toggle-humburger span {
  display: block;
  position: absolute;
  top: ($button-height / 2) - ($bar-thickness / 2);
  width: 36px;
  left: calc(50% - 18px);
  right: calc(50% - 18px);
  height: $bar-thickness;
  background: #fff;
  transition: background 2s;
}

.toggle-humburger span::before,
.toggle-humburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: $bar-thickness;
  background-color: #fff;
  content: "";
}

.toggle-humburger span::before {
  //top: -(($button-height / 2) - ($bar-thickness / 2) - $button-pad);
  top: -8px;
}

.toggle-humburger span::after {
  //bottom: -(($button-height / 2) - ($bar-thickness / 2) - $button-pad);
  bottom: -8px;
}

.toggle-humburger__htx {
  //background-color: #333;
  background: none;
}

.toggle-humburger__htx span {
  transition: background 0 $button-trans-delay;
}

.toggle-humburger__htx span::before,
.toggle-humburger__htx span::after {
  transition-duration: $button-trans-delay, $button-trans-delay;
  transition-delay: $button-trans-delay, 0;
}

.toggle-humburger__htx span::before {
  transition-property: top, transform;
}

.toggle-humburger__htx span::after {
  transition-property: bottom, transform;
}

/* active state, i.e. menu open */

.toggle-humburger__htx.active {
  //background-color: #333;
  background: none;
  /* position: fixed;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    top: 50px;
    right: 15px;
  } */
}

.toggle-humburger__htx.active span {
  background: none;
}

.toggle-humburger__htx.active span::before {
  background: #fff;
  top: 0;
  transform: rotate(45deg);
}

.toggle-humburger__htx.active span::after {
  background: #fff;
  bottom: 0;
  transform: rotate(-45deg);
}

.toggle-humburger__htx.active span::before,
.toggle-humburger__htx.active span::after {
  transition-delay: 0, $button-trans-delay;
}
