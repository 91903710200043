$space-padding-list: 15px;

.list-address {
    display: flex;
    border-top: 1px solid $border-color-gray;
    padding: 22px 0;
    

    @include media-breakpoint-up(xs) {
        flex-direction: column;
    }
    @include media-breakpoint-up(sm) {
        flex-direction: row;
        flex-wrap: wrap;
    }
    @include media-breakpoint-up(lg) {
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    &__col {
        @include media-breakpoint-up(xs) {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 5px 0;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }

        @include media-breakpoint-up(sm) {
            flex: 0 0 50%;
            max-width: 50%;
            padding-left: $space-padding-list;
            padding-right: $space-padding-list;

            &:first-child {
                padding-left: 0 !important;
            }

            &:last-child {
                padding-right: 0 !important;
            }

            &:nth-child(2n + 0) {
                padding-right: 0;
            }
            &:nth-child(2n + 1) {
                padding-left: 0;
            }
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 (100% / 3);
            max-width: (100% / 3);

            &:nth-child(2n + 0) {
                padding-right: $space-padding-list;
            }
            &:nth-child(2n + 1) {
                padding-left: $space-padding-list;
            }

            &:nth-child(3n + 0) {
                padding-right: 0;
            }
            &:nth-child(3n + 1) {
                padding-left: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
            max-width: 30%;
            padding-top: 0;
            padding-bottom: 0;

            &:nth-child(2n + 0) {
                padding-right: $space-padding-list;
            }
            &:nth-child(2n + 1) {
                padding-left: $space-padding-list;
            }

            &:nth-child(3n + 0) {
                padding-right: $space-padding-list;
            }
            &:nth-child(3n + 1) {
                padding-left: $space-padding-list;
            }
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 auto;
            max-width: none;
        }
    }

    @include media-breakpoint-up(lg) {
        &__region {
            flex: 0 0 15%;
            max-width: 15%;
        }

        &__city {
            flex: 0 0 14%;
            max-width: 14%;
        }

        &__street {
            flex: 0 0 23%;
            max-width: 23%;
        }

        &__schedule {
            max-width: 26%;
            flex: 0 0 26%;
        }

        &__representative {
            flex: 0 0 22%;
            max-width: 22%;
        }
    }
}
