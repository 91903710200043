.footer {
    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
    &--color-light {
        color: #cc9289;

        a {
            color: #cc9289;
        }
    }
}
