.headings-h1,
.headings-h2,
.headings-h3 {
    font-weight: normal;
}

.headings-h1 {
    font-family: $font-family-heading;
    text-transform: uppercase;
    margin-bottom: 10px;
	line-height: 1;
	margin-bottom: 1.176em;
    color: $color-heading;
	
	@include media-breakpoint-up(xs) {
        font-size: 1.225em;
    }
    @include media-breakpoint-up(sm) {
        font-size: $h2-size;
    }
    @include media-breakpoint-up(md) {
        font-size: $h1-size;
    }
}

.headings-h2 {
    font-family: $font-family-subheading;
    text-transform: uppercase;
    margin-bottom: 1.176em;
    line-height: 1;
    color: $color-heading;
	
	@include media-breakpoint-up(xs) {
        font-size: 1.225em;
    }
    @include media-breakpoint-up(sm) {
        font-size: $h2-size;
    }
}

.headings-h3 {
    font-family: $font-family-subheading;
    line-height: 1;
    margin-bottom: 17px;
    font-size: $h3-size;
    color: $color-heading;
}

h1 {
    //@extend .headings-h1;
}
h2 {
    @extend .headings-h2;
}
h3 {
    @extend .headings-h3;
}
