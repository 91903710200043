.phone-header {
    display: flex;
    color: #fff;

    &__group {
        display: flex;
        flex-direction: column;
        padding-right: 4px;
        flex: 0 0 auto;

        small {
            color: #9b868a;
            font-size: 0.613em;
            text-align: right;
            line-height: 1;
        }
    }

    &__link {
        margin-bottom: -4px;
        a {
            color: #ffffff;
            font-size: 0.907em;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            span {
                font-family: $font-family-bold;
            }
        }
    }
}