.slider {
	@include media-breakpoint-up(xs) {
		padding-left: 20px;
		padding-right: 20px;
	}
	@include media-breakpoint-up(md) {
		padding-left: 65px;
		padding-right: 65px;
	}
	@include media-breakpoint-up(xl) {
		padding-left: 15px;
		padding-right: 15px;
	}

	.slick-prev,
	.slick-next {
		top: 135px;

		@include media-breakpoint-up(xs) {
			width: 25px;
			height: 25px;
		}
		@include media-breakpoint-up(md) {
			width: 40px;
			height: 40px;
		}
	}
	.slick-prev {
		transform: rotate(180deg);
		top: 123px;

		@include media-breakpoint-up(xs) {
			left: -11px;
		}
		@include media-breakpoint-up(md) {
			left: -51px;
		}
	}
	.slick-next {
		@include media-breakpoint-up(xs) {
			right: -11px;
		}
		@include media-breakpoint-up(md) {
			right: -51px;
		}
	}
}

.slide {
	padding: 0 11.5px;

	&--gallery {
		@include media-breakpoint-up(xs) {
			flex: 0 0 100%;
			max-width: 100%;
			margin-bottom: 20px;
		}
		@include media-breakpoint-up(sm) {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: 20px;
		}
		@include media-breakpoint-up(md) {
			flex: 0 0 (100%/3);
			max-width: (100%/3);
		}
	}

	&__image {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 25px;
		height: 250px;

		a {
			height: 100%;
			display: flex;
			align-items: center;
		}
	}

	&__img {
		max-height: 100%;
	}

	&__content {
		font-size: 0.907em;
		color: $color-note;
		line-height: 1.27;
		text-align: center;
	}
}
