///* ========================================================================
//   #FONT-FACES
//   ======================================================================== */


@font-face {
    font-family: 'OpenSans-ExtraboldItalic';
    src: url('../fonts/OpenSans-ExtraboldItalic.eot');
    src: url('../fonts/OpenSans-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-ExtraboldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-ExtraboldItalic.woff') format('woff'),
        url('../fonts/OpenSans-ExtraboldItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-ExtraboldItalic.svg#OpenSans-ExtraboldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-BoldItalic';
    src: url('../fonts/OpenSans-BoldItalic.eot');
    src: url('../fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-BoldItalic.woff') format('woff'),
        url('../fonts/OpenSans-BoldItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-SemiboldItalic';
    src: url('../fonts/OpenSans-SemiboldItalic.eot');
    src: url('../fonts/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-SemiboldItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiboldItalic.woff') format('woff'),
        url('../fonts/OpenSans-SemiboldItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-SemiboldItalic.svg#OpenSans-SemiboldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../fonts/OpenSans.eot');
    src: url('../fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans.woff2') format('woff2'),
        url('../fonts/OpenSans.woff') format('woff'),
        url('../fonts/OpenSans.ttf') format('truetype'),
        url('../fonts/OpenSans.svg#OpenSans') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff'),
        url('../fonts/OpenSans-Bold.ttf') format('truetype'),
        url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-CondensedBold';
    src: url('../fonts/OpenSans-CondensedBold.eot');
    src: url('../fonts/OpenSans-CondensedBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-CondensedBold.woff2') format('woff2'),
        url('../fonts/OpenSans-CondensedBold.woff') format('woff'),
        url('../fonts/OpenSans-CondensedBold.ttf') format('truetype'),
        url('../fonts/OpenSans-CondensedBold.svg#OpenSans-CondensedBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url('../fonts/OpenSans-Light.eot');
    src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/OpenSans-Light.woff') format('woff'),
        url('../fonts/OpenSans-Light.ttf') format('truetype'),
        url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-CondensedLight';
    src: url('../fonts/OpenSans-CondensedLight.eot');
    src: url('../fonts/OpenSans-CondensedLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-CondensedLight.woff2') format('woff2'),
        url('../fonts/OpenSans-CondensedLight.woff') format('woff'),
        url('../fonts/OpenSans-CondensedLight.ttf') format('truetype'),
        url('../fonts/OpenSans-CondensedLight.svg#OpenSans-CondensedLight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Italic';
    src: url('../fonts/OpenSans-Italic.eot');
    src: url('../fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Italic.woff2') format('woff2'),
        url('../fonts/OpenSans-Italic.woff') format('woff'),
        url('../fonts/OpenSans-Italic.ttf') format('truetype'),
        url('../fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSansLight-Italic';
    src: url('../fonts/OpenSansLight-Italic.eot');
    src: url('../fonts/OpenSansLight-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSansLight-Italic.woff2') format('woff2'),
        url('../fonts/OpenSansLight-Italic.woff') format('woff'),
        url('../fonts/OpenSansLight-Italic.ttf') format('truetype'),
        url('../fonts/OpenSansLight-Italic.svg#OpenSansLight-Italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Extrabold';
    src: url('../fonts/OpenSans-Extrabold.eot');
    src: url('../fonts/OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Extrabold.woff2') format('woff2'),
        url('../fonts/OpenSans-Extrabold.woff') format('woff'),
        url('../fonts/OpenSans-Extrabold.ttf') format('truetype'),
        url('../fonts/OpenSans-Extrabold.svg#OpenSans-Extrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-CondensedLightItalic';
    src: url('../fonts/OpenSans-CondensedLightItalic.eot');
    src: url('../fonts/OpenSans-CondensedLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-CondensedLightItalic.woff2') format('woff2'),
        url('../fonts/OpenSans-CondensedLightItalic.woff') format('woff'),
        url('../fonts/OpenSans-CondensedLightItalic.ttf') format('truetype'),
        url('../fonts/OpenSans-CondensedLightItalic.svg#OpenSans-CondensedLightItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Semibold';
    src: url('../fonts/OpenSans-Semibold.eot');
    src: url('../fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Semibold.woff2') format('woff2'),
        url('../fonts/OpenSans-Semibold.woff') format('woff'),
        url('../fonts/OpenSans-Semibold.ttf') format('truetype'),
        url('../fonts/OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AdobeFangsongStd-Regular-Alphabetic';
    src: url('../fonts/AdobeFangsongStd-Regular-Alphabetic.eot');
    src: url('../fonts/AdobeFangsongStd-Regular-Alphabetic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AdobeFangsongStd-Regular-Alphabetic.woff2') format('woff2'),
        url('../fonts/AdobeFangsongStd-Regular-Alphabetic.woff') format('woff'),
        url('../fonts/AdobeFangsongStd-Regular-Alphabetic.ttf') format('truetype'),
        url('../fonts/AdobeFangsongStd-Regular-Alphabetic.svg#AdobeFangsongStd-Regular-Alphabetic') format('svg');
    font-weight: normal;
    font-style: normal;
}