.label {
	&--check {

		.field-desc {
			position: relative;

			&::before {
				content: '';
				display: inline-block;
				position: absolute;
				width: 28px;
				height: 28px;
				border: 1px solid transparent;
				background: white;
				margin-right: 22px;
				left: -50px;

				// @include susy-breakpoint($md){
				// 	top: 13px;
				// }
			}

			&.error {
				&::before {
					border-color: red;
				}
			}
		}

		.input--check:checked + .field-desc::after {
			content: '';
			display: inline-block;
			position: absolute;
			width: 15px;
			height: 14px;
			border-left: 2px solid blue;
			border-top: 2px solid blue;
			// margin-right: 22px;
			top: 6px;
			left: -44px;
			transform: rotate(-135deg);

			// @include susy-breakpoint($md){
			// 	top: 16px;
			// }
		}
	}
}