.contacts {
    border-right: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    &:last-child {
        border: none;
    }

    &__heading {
        font-family: $font-family-regular;
        text-transform: uppercase;
        line-height: 1;
        margin-bottom: 17px;
    }

    &__list {
        margin: 0;
        list-style: none;
        line-height: 1;
    }

    &__item {
        display: flex;
        flex-direction: column;
        margin-bottom: 13px;

        span {
            font-size: 0.858em;
            margin-top: 4px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        font-family: $font-family-bold;
        text-decoration: none;
        color: $color-secondary;

        &:hover {
            text-decoration: underline;
        }
    }

    &__icon {
        width: 33px;
        height: 33px;

        &:hover {
            filter: brightness(0.6);
        }
    }

    &__img {
        img {
            width: 100%;
        }
    }
}
