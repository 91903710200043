.sidebar {
    margin: 0;
    font-size: 0.978em;
    line-height: 1.2;

    &__item {
        margin-top: 22px;

        &:first-child {
            margin-top: 0;
        }

        &--border {
            border-bottom: 1px solid #ededed;
            padding-bottom: 17px;

            &:last-child {
                border: none;
            }
        }
    }

    &__partition {
        font-family: $font-family-regular;
        text-transform: uppercase;
    }

    &__info {
        margin-top: 7px;
    }

    &__link {
        text-decoration: none;
        color: $color-secondary;
        font-family: $font-family-bold;
        transform: scaleY(1.0014);

        &:hover {
            text-decoration: underline;
        }
    }
}
