.products {
    &--container {
        @extend .container;

        max-width: 840px;
    }

    &--row {
        align-items: flex-start;
        justify-content: center;

        @include media-breakpoint-up(md) {
            justify-content: space-between;
        }
    }
}

.product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 33px;

    @include media-breakpoint-up(xs) {
        flex: 0 0 50%;
        padding-left: 10px;
        padding-right: 10px;
    }

    @media (min-width: 410px) {
        flex: 0 0 (1/3 * 100%);
    }

    @include media-breakpoint-up(md) {
        flex: 0 0 240px;
        padding-left: 15px;
        padding-right: 15px;
    }

    &:hover {
        text-decoration: underline;
        color: #fff;
        font-family: "OpenSans", sans-serif;
    }

    &__image {
        display: flex;
        justify-content: center;
        margin-bottom: 12px;
        
        @include media-breakpoint-up(xs) {
            align-items: flex-end;
            height: 60px;
        }
    
        @include media-breakpoint-up(md) {
            align-items: center;
            height: 80px;
        }
    }

    &__img {
        max-height: 100%;
        @include media-breakpoint-up(xs) {
            width: 60px;
        }
    
        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    &__heading {
        font-family: $font-family-regular;
        color: #ffffff;
        
        line-height: 1.154;
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoint-up(xs) {
            font-size: .738em;
        }
    
        @include media-breakpoint-up(md) {
            font-size: 1.104em;
        }
    }
}
