.breadcrumbs {
    margin-left: 0;
    list-style: none;
    color: #999999;
    margin-bottom: 5px;

    &__item {
        padding-right: 8px;
        display: inline;
        font-size: 0.9em;
    }

    &__link {
        color: #999;
        text-decoration: none;
    }

    &__marker {
        color: #9b9b9b;
    }

    &__active {
        color: #333;
    }
}
