.filter {
    display: flex;

    @include media-breakpoint-up(xs) {
        flex-wrap: wrap;
    }

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
    }

    &__group {
        display: flex;
        align-items: center;
        border: 1px solid $border-color-gray;

        @include media-breakpoint-up(xs) {
            flex-wrap: wrap;
            flex: 0 0 100%;
            max-width: 100%;
            justify-content: center;
            margin-bottom: 5px;
        }

        @include media-breakpoint-up(md) {
            flex: auto;
            max-width: initial;
            margin-right: 5px;
            margin-bottom: 0;
            justify-content: initial;
        }

        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
            flex: 1 0 auto;
        }
    }

    &--field {
        border: none;
        background: transparent;
        border-radius: 0;

        @include media-breakpoint-up(xs) {
            padding: 13px 17px;
            flex: 0 0 95%;
            max-width: 95%;
        }

        @include media-breakpoint-up(md) {
            padding: 1.06% 17px;
            flex: 1 0 auto;
            max-width: 25%;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
            max-width: initial;
        }
    }

    &__dropdown {
        display: flex;
        justify-content: space-between;
        position: relative;

        @include media-breakpoint-up(xs) {
            border-bottom: 1px solid $border-color-gray;
        }

        @include media-breakpoint-up(md) {
            border-right: 1px solid $border-color-gray;
            border-bottom: 0;
        }

        & select {
            max-width: 100%;
            border: none;
            background: transparent;
            border-radius: 0;
            appearance: none;
            padding-right: 25px;
            cursor: pointer;
            z-index: 1;

            &:focus {
                border-color: transparent;
                outline: none;
                background: none;
            }
        }

        &::after {
            content: "";
            background-image: url("/site/templates/static/img/slider/arrow.svg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            width: 17px;
            height: 17px;
            filter: grayscale(1) brightness(2.3);
            transform: rotate(90deg);
            position: absolute;
            top: 10;
            right: 13px;
            z-index: 0;
        }
    }

    &__search {
        @include media-breakpoint-up(md) {
            flex: 1 0 24%;
        }

        &::placeholder {
            opacity: 0.4;
        }
    }

    &__submit {
        @include media-breakpoint-up(xs) {
            flex: 0 0 100%;
            max-width: 100%;
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 auto;
        }
    }
}

[v-cloak] { display: none }