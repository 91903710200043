// <div class="col-md-12">
// <label class="checkbox">
// 	<span class="checkbox__box">
// 		<input class="checkbox__control" type="checkbox" autocomplete="off" name="name1" value="val_1">
// 	</span>
// 	<span class="checkbox__text">
// 		Я согласен с 
// 		<a class="form__link" href="/" target="_blank">политикой конфиденциальности</a>
// 	</span>
// </label>
// </div>

.checkbox {

	display: flex;
	align-items: center;

	&__control {
		display: none;
	}

	&__box {
		position: relative;
		background-color: #ffffff;
		border: 1px solid #666666;
		width: 1em;
		height: 1em;
		margin-right: 0.5em;
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		justify-content: center;

		.checkbox__control:checked + &:before {
			content: '';
			width: 60%;
			height: 60%;
			background-color: $color-heading;
			position: absolute;
		}
	}

	&__text {
		user-select: none;
	}
}